const config = {
	"gatsby": {
		"pathPrefix": "/",
		// "siteUrl": "https://learn.hasura.io",
		"siteUrl": "https://helps.app",
		"gaTrackingId": "G-TKTSNDEJ7B"
	},
	"header": {
		"logo": "",
		"logoLink": "https://kkj.taipei.app",
		"title": "KKJ Blog",
		"githubUrl": "https://github.com/jerrychen1013/",
		"helpUrl": "",
		"tweetText": "",
		"links": [
			{ "text": "", "link": ""}
		],
		"search": {
			"enabled": false,
			"indexName": "",
			"algoliaAppId": process.env.GATSBY_ALGOLIA_APP_ID,
			"algoliaSearchKey": process.env.GATSBY_ALGOLIA_SEARCH_KEY,
			"algoliaAdminKey": process.env.ALGOLIA_ADMIN_KEY
		}
	},
	"sidebar": {
		"forcedNavOrder": [
			"/introduction",
      "/codeblock",
      "/travel"
		],
		"links": [
			{ "text": "KyotoKingJerry", "link": "https://kkj.helps.app"},
		],
		"frontline": false,
		"ignoreIndex": true,
	},
	"siteMetadata": {
		"title": "KKJ Blog | KKJ",
		"description": "KKJ's blog for coding & travel events",
		"ogImage": null,
		"docsLocation": "https://kkj.helps.app/introduction",
		"favicon": "https://kkj.helps.app/static/favicon-33689de06b149cd491a0bacf26474fed.png"
	},
};

module.exports = config;
